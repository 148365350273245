<template>
  <div class="setpolygon" ref="fullpanel">
    <div class="setpolygon_box" ref="insidepanel">
      <img  v-if="appStyle == 'stats'"  
        @click="closePanel"
        class="setpolygon_box:close"
        src="~@/assets/images/general/x_stats.svg"
        alt
      />
      <img  v-else  
        @click="closePanel"
        class="setpolygon_box:close"
        src="~@/assets/images/general/x.svg"
        alt
      />
      <div class="setpolygon_title">SELECT POLYGON VIEW</div>
      <div class="setpolygon_container">
        <div
          class="setpolygon_container:single"
          v-for="(t, index) in polys"
          :key="index"
        >
          <div class="setpolygon_container:single_val" @click="setPoly(t)">
            {{ t.poly }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TweenMax } from "gsap";
import axios from "axios";
export default {
  data() {
    return {};
  },
  watch: {
    poly(val) {
      if (val) {
        this.openPanel();
      }
    },
  },
  methods: {
    setPoly(t) {
      this.$store.commit("setpoly", t.poly);
      this.closePanel();
    },
    openPanel() {
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 1,
        scale: 1,
        delay: 0.1,
      });
    },
    closePanel() {
      this.$store.commit("poly", false);
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 0, delay: 0.1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 0,
        scale: 0.9,
      });
    },
  },
  mounted() {},
  created() {},
  computed: {
    poly() {
      return this.$store.state.field.poly;
    },
    polys() {
      return this.$store.state.field.polys;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
};
</script>

<style lang="scss" scoped>
.setpolygon {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //background-color: var(--bg-panel-color);
  color: var(--color);
  visibility: hidden;
  text-align: center;
  z-index: 1000;
  &_box {
    color: var(--color);
    width: auto;
    display: inline-block;
    padding: 30px;
    background-image: var(--background-image);
    background-color: var(--bg-panel-color);
    border: var(--border);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    position: absolute;
    &\:close {
      padding: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 650px;
    &\:single {
      width: (100%/3);
      padding: 20px;
      &_val {
        background-color: var(--background-color);
        color: var(--color);
      }
      &_valActive {
      }
    }
  }
  &_text {
    width: var(--setview-width);
    text-align: center;
  }
 }
</style>
