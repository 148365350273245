<template>
  <div :class="'playersTeam_header' + side" v-if="side == 'home'">
    <div :class="'playersTeam_header:container' + side">
      <div class="controller">
        <div
          class="controller_hide"
          :ref="'controls' + side"
          v-if="isActivePlayersControl"
        >
          <button
            @click="hidePalyerName(side)"
            class="controller_hide:hide"
            ref="hide"
          >
            Hide
          </button>
          <button
            @click="showPalyerName(side)"
            class="controller_hide:show"
            ref="show"
          >
            Show
          </button>
        </div>
        <div class="controller_hide" v-if="!isActivePlayersControl" />
        <div
          v-if="isActivePlayersControl"
          :class="'controller_arrows' + side"
          :ref="'controls_arrows' + side"
        >
          <div
            :class="'controller_arrows' + side + ':left'"
            @click="hideParameters"
          >
            <img src="~@/assets/images/field/arrow-left.svg" />
          </div>
          <div
            :class="'controller_arrows' + side + ':right'"
            @click="showparameters"
          >
            <img src="~@/assets/images/field/arrow-right.svg" />
          </div>
        </div>
        <div
          v-if="!isActivePlayersControl"
          :class="'controller_arrows' + side"
        />
      </div>
      <div class="parameters:left">
        <div
          class="parameters_single"
          v-for="(t, index) in parameters[0]"
          :key="index"
        >
          <div
            :class="'parameters_single:val' + side"
            v-if="filters.includes(t.name)"
            @click="removeParamFilter(t.name)"
          >
            {{ t.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else :class="'playersTeam_header' + side">
    <div :class="'playersTeam_header:container' + side">
      <div class="parameters:right">
        <div
          class="parameters_single"
          v-for="(t, index) in parameters[0]"
          :key="index"
        >
          <div
            :class="'parameters_single:val' + side"
            v-if="filters.includes(t.name)"
            @click="removeParamFilter(t.name)"
          >
            {{ t.name }}
          </div>
        </div>
      </div>
      <div class="controller:right">
        <div
          class="controller_hide"
          :ref="'controls' + side"
          v-if="isActivePlayersControl"
        >
          <button
            @click="hidePalyerName(side)"
            class="controller_hide:hide"
            ref="hide"
          >
            Hide
          </button>
          <button
            @click="showPalyerName(side)"
            class="controller_hide:show"
            ref="show"
          >
            Show
          </button>
        </div>
        <div class="controller_hide" v-if="!isActivePlayersControl" />
        <div
          v-if="isActivePlayersControl"
          :class="'controller_arrows' + side"
          :ref="'controls_arrows' + side"
        >
          <div
            :class="'controller_arrows' + side + ':left'"
            @click="showparameters"
          >
            <img src="~@/assets/images/field/arrow-left.svg" />
          </div>
          <div
            :class="'controller_arrows' + side + ':right'"
            @click="hideParameters"
          >
            <img src="~@/assets/images/field/arrow-right.svg" />
          </div>
        </div>
        <div
          v-if="!isActivePlayersControl"
          :class="'controller_arrows' + side"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TweenMax } from "gsap";
import CSSPlugin from "gsap/CSSPlugin";
export default {
  props: {
    parameters: {
      type: Array,
      required: true,
    },
    side: {
      type: String,
      required: true,
    },
  },
  computed: {
    filters() {
      return this.$store.state.field.filters;
    },
    isActivePlayersControl() {
      return this.$store.state.isActivePlayersControl;
    },
  },
  methods: {
    removeParamFilter(item) {
      const newparameter = this.filters.filter((e) => e !== item);
      this.$store.commit("filtersParameter", newparameter);
    },
    hidePalyerName(side) {
      if (side == "home") {
        this.$store.commit("hidehome", true);
        // console.log("home");
      } else {
        this.$store.commit("hideaway", true);
        // console.log("away");
      }

      const hide = this.$refs.hide;
      hide.style.display = "none";
      const show = this.$refs.show;
      show.style.display = "block";

      const controls = this.$refs["controls" + side];
      const controls_arrows = this.$refs["controls_arrows" + side];
      TweenMax.staggerTo(controls, 0.3, { width: 54, delay: 0.2 }, 0.03);
      TweenMax.to(controls_arrows, 0.3, {
        css: { marginLeft: 0, width: 0, autoAlpha: 0 },
      });
      const playerdata = document.getElementsByClassName(
        "player_data_field" + side
      );
      if (side == "away") {
        TweenMax.staggerTo(playerdata, 0.3, { css: { marginRight: 65 } }, 0.03);
      }
    },
    showPalyerName(side) {
      if (side == "home") {
        this.$store.commit("hidehome", false);
      } else {
        this.$store.commit("hideaway", false);
      }

      const show = this.$refs.show;
      show.style.display = "none";
      const hide = this.$refs.hide;
      hide.style.display = "block";

      const controls = this.$refs["controls" + side];
      const controls_arrows = this.$refs["controls_arrows" + side];
      TweenMax.staggerTo(controls, 0.3, { width: 54 }, 0.03);
      TweenMax.to(controls_arrows, 0.3, {
        css: { marginLeft: 55, width: 74, autoAlpha: 1 },
      });
      if (side == "home") {
        TweenMax.to(controls_arrows, 0.3, {
          css: { marginLeft: 55, width: 146, autoAlpha: 1 },
        });
      } else {
        TweenMax.to(controls_arrows, 0.3, {
          css: { marginLeft: 55, width: 70, autoAlpha: 1 },
        });
      }

      const playerdata = document.getElementsByClassName(
        "player_data_field" + side
      );
      if (side == "away") {
        TweenMax.staggerTo(
          playerdata,
          0.3,
          { css: { marginRight: 206 } },
          0.03
        );
      }
    },
    hideParameters() {
      const param = document.getElementsByClassName(
        "parameters_single:val" + this.side
      );
      const paramHide = document.getElementsByClassName(
        "paramHide" + this.side
      );
      if (param.length > paramHide.length) {
        let count = 0;
        for (let x = 0; x < paramHide.length; x++) {
          count++;
        }
        param[param.length - count - 1].classList.add("paramHide" + this.side);
        TweenMax.to(param[param.length - count - 1], 0.1, { autoAlpha: 0 });
        const fitler = param[param.length - count - 1].innerHTML;
        const paramPlayer = document.querySelectorAll(
          "[data-filter='" + this.side + fitler + "']"
        );
        const paramDot = document.querySelectorAll(
          "[data-filter='" + this.side + fitler + "-dot']"
        );
        TweenMax.staggerTo(paramPlayer, 0.2, { autoAlpha: 0, width: 0 }, 0.03);
        TweenMax.staggerTo(
          paramDot,
          0.2,
          { css: { autoAlpha: 1, display: "flex" } },
          0.03
        );
      }
    },
    showparameters() {
      const param = document.getElementsByClassName(
        "parameters_single:val" + this.side
      );
      const paramHide = document.getElementsByClassName(
        "paramHide" + this.side
      );
      if (paramHide.length > 0) {
        let count = 0;
        for (let x = 0; x < param.length; x++) {
          if (param[x].classList.contains("paramHide" + this.side)) {
            param[x].classList.remove("paramHide" + this.side);
            TweenMax.to(param[x], 0.1, { autoAlpha: 1 });
            const fitler = param[x].innerHTML;
            const paramPlayer = document.querySelectorAll(
              "[data-filter='" + this.side + fitler + "']"
            );
            const paramDot = document.querySelectorAll(
              "[data-filter='" + this.side + fitler + "-dot']"
            );
            TweenMax.staggerTo(
              paramDot,
              0.2,
              { css: { autoAlpha: 0, display: "none" } },
              0.03
            );
            TweenMax.staggerTo(
              paramPlayer,
              0.2,
              { autoAlpha: 1, width: 41 },
              0.03
            );
            break;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.playersTeam_headerhome,
.playersTeam_headeraway {
  display: flex;
}
.playersTeam_headeraway {
  justify-content: flex-end;
}
.playersTeam_header\:containerhome,
.playersTeam_header\:containeraway {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
}
.playersTeam_header\:containerhome {
  justify-content: flex-start;
}
.playersTeam_header\:containeraway {
  justify-content: flex-end;
}
.parameters {
  &\:left,
  &\:right {
    display: flex;
  }
  &\:left {
    padding-left: 15px;
  }
  &\:right {
    padding-right: 23px;
    flex-direction: row-reverse;
  }
  &_single {
    font-size: 10px;
    margin: 0 4px;
    &\:valhome,
    &\:valaway {
      width: 41px;
      text-align: center;
    }
  }
}
.controller:left {
}
.controller {
  &\:right {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
.controller {
  height: 20px;
  &_arrowshome,
  &_arrowsaway {
    display: flex;
    flex-direction: row;
    margin-left: 55px;
    width: 146px;
    &\:left {
      padding-right: 15px;
    }
    &\:right {
      padding-left: 15px;
    }
  }
  &_arrowsaway {
    width: 70px;
  }
  &_hide {
    position: relative;
    // margin-left: 20px;
    width: 54px;
    margin-left: 5px;
    button {
      font-size: 12px;
    }
    &\:show,
    &\:hide {
      position: absolute;
      background-color: transparent;
      border: none;
      color: white;
    }
    &\:show {
      display: none;
    }
  }
}
.controllerPh {
  width: 146px;
}
</style>
