<template>
  <div id="canvasWrapper3D">
    <canvas id="c3D"></canvas>
  </div>
</template>
<script>
import * as models from "@/mixins/models/models.js";
import * as THREE from "three";
import { TextureLoader } from "three";
import { TweenMax } from "gsap";
//import { SpriteAlignment } from "three/";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import optimer_bold from "three/examples/fonts/optimer_bold.typeface.json";
export default {
  name: "soccerfield3D",
  props: {
    matchSnapshot: {},
  },
  data() {
    return {
      selected: "N",
      matchSnapshotData: {},
      // Tutti i dati inerenti la visualizzazione 3D mi metto qui affichè siano globali
      scene: null,
      pivot: null,
      font: null,
      canvas: null,
      renderer: null,
      container: null,
      width: 0,
      height: 0,
      camera: null,
      fov: 0,
      aspect: 0,
      near: 0,
      far: 0,
      controls: null,
      playerMesh: null,
      planeNum: null,
      homePlayersJNums: [], //Salvo la lista dei Jersey Numbers per i quali ho creato il mesh
      awayPlayersJNums: [], //Salvo la lista dei Jersey Numbers per i quali ho creato il mesh
      prevBallX: 0,
      saveBall: {},
      playerGeometry: null,
      playerMaterial: null,
      player3DTextMaterial: null,
      playerNumPlaneGeom: null,
      spotlightTemplateGeom: null,
      spotlightTemplateMat: null,
      textlabels: [],
      video: null,
      videoImage: null,
      videoImageContext: null,
      videoTexture: null,
      polygonsCount: -1,
      playersUpdateTime: 0,
      areasUpdateTime: 0,
      time: 0,
      image_length: 1111,
      image_height: 648,
    };
  },
  watch: {
    cameraPoint(v) {
      //console.log(v);
      var vm = this;
      vm.camera.position.set(v.x, v.y, v.z);
      vm.controls.update();

      // vm.planeNum.rotation.y = 0.5;
      // vm.planeNum.rotation.x = 1;

      // vm.planeNum.lookAt(vm.camera.position);

      // TweenMax.to(vm.planeNum.rotation, 0.5, { x: 0.8, y: 0.8 });

      // vm.planeNum.update();
    },
    // Whenever the movie prop changes, fetch new data
    matchSnapshot(matchSnapshot) {
      this.renderRow(matchSnapshot);
    },
  },
  computed: {
    view() {
      return this.$store.state.field.setview;
    },
    cameraPoint() {
      return this.$store.state.field.camera;
    },
    poly() {
      return this.$store.state.field.setpoly;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  mounted() {
    var vm = this;

    let x = this.cameraPoint.x,
      y = this.cameraPoint.y,
      z = this.cameraPoint.z;

    function init() {
      vm.width = window.innerWidth;
      vm.height = window.innerHeight;

      vm.fov = 45;
      vm.aspect = vm.width / vm.height;
      vm.near = 0.1;
      vm.far = 10000;

      vm.scene = new THREE.Scene();

      vm.camera = new THREE.PerspectiveCamera(
        vm.fov,
        vm.aspect,
        vm.near,
        vm.far
      );
      // vm.camera = new THREE.OrthographicCamera(
      //   vm.width / -2,
      //   vm.width / 2,
      //   vm.height / 2,
      //   vm.height / -2,
      //   1,
      //   1000
      // );
      vm.camera.position.x = x;
      vm.camera.position.y = y;
      vm.camera.position.z = z;
      vm.camera.lookAt(new THREE.Vector3(0, 0, 0));

      vm.container = document.getElementById("canvasWrapper3D");
      vm.canvas = document.getElementById("c3D");
      vm.renderer = new THREE.WebGLRenderer({ alpha: true, canvas: vm.canvas });

      //Disabilito Orbit Control
      vm.controls = new OrbitControls(vm.camera, vm.renderer.domElement);
      vm.controls.target = new THREE.Vector3(0, 0, 0);
      vm.controls.autoRotate = true;
      vm.controls.update();
      //Disabilito Orbit Control
      vm.controls.enabled = false;
    }

    function createPlayerMeshPrototype() {
      vm.playerGeometry = new THREE.CylinderGeometry(5, 5, 10, 16);
      vm.playerMaterial = new THREE.MeshPhongMaterial({ color: 0xd97d34 });
      vm.player3DTextMaterial = new THREE.MeshPhongMaterial({ color: "white" });
      vm.playerNumPlaneGeom = new THREE.PlaneGeometry(16, 16, 2, 2);

      vm.font = new THREE.FontLoader().parse(optimer_bold);

      vm.spotlightTemplateGeom = new THREE.CylinderGeometry(0, 30, 100, 20, 4);
      vm.spotlightTemplateMat = new THREE.MeshBasicMaterial({
        color: 0xffff00,
        opacity: 0.5,
        transparent: true,
        visible: true,
      });
    }

    function createBall() {
      var degrees = 35;
      var angleRad = (degrees * Math.PI) / 180;
      var friction = 1;
      var gravity = 0.2;
      var bounciness = 0.9;

      var ballRadius = 5;
      var ballCircumference = Math.PI * ballRadius * 2;
      var ballVelocity = new THREE.Vector3();
      var ballRotationAxis = new THREE.Vector3(0, 1, 0);

      var texture = new TextureLoader().load(
        require("@/assets/images/field/texture/ball.jpg")
      );
      var geometry = new THREE.SphereGeometry(ballRadius, 32, 16);
      var material = new THREE.MeshPhongMaterial({
        map: texture,
        bumpMap: texture,
        bumpScale: 0.01,
      });
      let ball = new THREE.Mesh(geometry, material);
      ball.castShadow = true;
      ball.receiveShadow = false;
      ball.name = "ball";
      vm.scene.add(ball);

      // Setting Rotazione
      var rotation_matrix = new THREE.Matrix4().makeRotationZ(angleRad);
      ball.rotation.set(Math.PI / 2, Math.PI / 4, Math.PI / 4); // Set initial rotation
      ball.matrix.makeRotationFromEuler(ball.rotation); // Apply rotation to the object's matrix
    }

    function createFloor() {
      var floorTexture = new TextureLoader().load(
        require("@/assets/images/field/texture/fieldbg.png")
      );
      var floorTextureStats = new TextureLoader().load(
        require("@/assets/images/field/texture/fieldbgStats.png")
      );
      var planeGeometry = new THREE.PlaneGeometry(
        vm.image_length,
        vm.image_height
      );
      //  var planeMaterial = new THREE.MeshStandardMaterial( { color: 0x45545a } )
      var planeMaterial;
      if (vm.appStyle == "stats")
        planeMaterial = new THREE.MeshBasicMaterial({ map: floorTextureStats });
      else planeMaterial = new THREE.MeshBasicMaterial({ map: floorTexture });

      let plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.position.set(0, 0, 0);

      //plane.receiveShadow = true;
      //plane.rotation.x = (-90 * Math.PI) / 180;
      plane.rotation.x -= Math.PI / 2; // Rotate the floor 90 degrees
      vm.scene.add(plane);
    }

    function lighting() {
      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(-100, 500, 400);
      light.castShadow = true;

      const d = 200;
      light.shadowCameraLeft = -d;
      light.shadowCameraRight = d;
      light.shadowCameraTop = d;
      light.shadowCameraBottom = -d;

      light.shadowCameraFar = 1000;

      vm.scene.add(light);
      vm.scene.add(new THREE.HemisphereLight(0xffffbb, 0x080820, 0.6));
      vm.scene.add(new THREE.AmbientLight(0xa59f75, 0.6));
    }

    function resize() {
      var width = vm.canvas.clientWidth;
      var height = vm.canvas.clientHeight;
      if (vm.canvas.width != width || vm.canvas.height != height) {
        vm.renderer.setSize(
          vm.canvas.clientWidth,
          vm.canvas.clientHeight,
          false
        ); // don't fucking update the style. Why does three.js fight CSS? It should respect CSS :(

        vm.camera.aspect = vm.canvas.clientWidth / vm.canvas.clientHeight;
        vm.camera.updateProjectionMatrix();
      }
    }

    function render() {
      resize();
      vm.renderer.render(vm.scene, vm.camera);
      requestAnimationFrame(render);
      for (var i = 0; i < vm.textlabels.length; i++) {
        vm.textlabels[i].updatePosition();
      }
    }

    function animate() {
      //Cancellare
      requestAnimationFrame(animate);
      vm.renderer.render(vm.scene, vm.camera);
    }

    init();
    lighting();
    createPlayerMeshPrototype();
    createFloor();
    createBall();

    render();
    //animate();
  },
  methods: {
    renderRow(matchSnapshot, vm) {
      //var matchSnapshot = new models.MatchSnapshot();
      //Object.assign(matchSnapshot, matchSnapshotJSONString);
      var singleRowData = new models.SingleRowData();
      Object.assign(singleRowData, matchSnapshot.singleRowData);
      var matchHullsData = new models.MatchHullsData();
      Object.assign(matchHullsData, matchSnapshot.matchHullsData);
      var matchVoronoiData = new models.MatchVoronoiData();
      Object.assign(matchVoronoiData, matchSnapshot.matchVoronoiData);

      // Disabilito tutti i giocatori e la palla
      // TODO: Disabilitare solo quelli attivi che non sono nel nuovo set di dati

      const now = Date.now();
      if (this.playersUpdateTime == 0) this.playersUpdateTime = now;

      // Salvo sempre l'ultima palla buona
      var newPointBall = new models.DrawPoint();
      Object.assign(newPointBall, singleRowData.ball);
      if (
        newPointBall.x != null &&
        newPointBall.x != undefined &&
        newPointBall.x != 0
      ) {
        this.saveBall = newPointBall;
      }

      if (now >= this.playersUpdateTime) {
        this.clearWithFilter(
          singleRowData.homePlayers,
          singleRowData.awayPlayers
        );
        //this.clear();

        var rPoint;
        // Ball
        var drawPointBall = new models.DrawPoint();
        Object.assign(drawPointBall, singleRowData.ball);

        // Se la palla non è buona uso l'ultima buona
        if (
          drawPointBall.x == null ||
          drawPointBall.x == undefined ||
          drawPointBall.x == 0
        ) {
          drawPointBall = this.saveBall;
          //console.log("Ball From saved data");
        }

        rPoint = new this.renderBall(
          this,
          drawPointBall.x,
          drawPointBall.y,
          this.prevBallX >= drawPointBall.x ? "CW" : "CCW"
        );
        if (drawPointBall.x != null && drawPointBall.x != undefined)
          this.prevBallX = drawPointBall.x;

        // Render Home Players
        // console.log(singleRowData);
        for (var i = 0; i < singleRowData.homePlayers.length; i++) {
          var playerData = new models.PlayerData();
          Object.assign(playerData, singleRowData.homePlayers[i]);
          rPoint = new this.renderPlayer(
            this,
            "Home",
            playerData.color,
            playerData.x,
            playerData.y,
            playerData.jerseyNum,
            drawPointBall,
            playerData.playerId
          );
        }

        // Render Away Players
        for (var i = 0; i < singleRowData.awayPlayers.length; i++) {
          var playerData = new models.PlayerData();
          Object.assign(playerData, singleRowData.awayPlayers[i]);
          rPoint = new this.renderPlayer(
            this,
            "Away",
            playerData.color,
            playerData.x,
            playerData.y,
            playerData.jerseyNum,
            drawPointBall,
            playerData.playerId
          );
        }

        this.clearPolygons(0);
        // Hulls
        if (this.poly == "Team Area") {
          this.renderPolygon(
            this,
            matchHullsData.currentHomePolygon,
            "blue",
            0,
            1
          );
          this.renderPolygon(
            this,
            matchHullsData.currentAwayPolygon,
            "red",
            1,
            2
          );
        }

        // Voronoi
        if (this.poly == "K-Regions") {
          for (
            var i = 0, n = matchVoronoiData.currentPolygons.length;
            i < n;
            ++i
          ) {
            this.renderPolygon(
              this,
              matchVoronoiData.currentPolygons[i],
              matchVoronoiData.currentHomeAwayFlags[i] == "H" ? "blue" : "red",
              i,
              matchVoronoiData.currentHomeAwayFlags[i] == "H" ? 0 : 1
              //matchVoronoiData.currentPoints[i]
            );
          }
        }
        this.playersUpdateTime += 1000;
      }
    },
    renderPlayer(vm, team, color, x, y, num, ball, trackId) {
      // Se il numero è "-1" ovvero non assegnato creo un numero negativo fittizio per riconoscere e tracciare il punto
      // Lo metto negativo così lo riconosco e non visualizzo in numero
      let showNum = true;
      if (num == "-1") {
        num = trackId * -1;
        showNum = false;
      }

      // Se il numero esiste aggiorno al posizione altrimenti creo in nuovo Mesh
      let numExists = false;
      if (
        (team == "Home" && vm.homePlayersJNums.includes(team + num)) ||
        (team == "Away" && vm.awayPlayersJNums.includes(team + num))
      ) {
        numExists = true;
      }

      if (!numExists) {
        // Aggiunge Numero ad Array
        if (team == "Home") vm.homePlayersJNums.push(team + num);
        else vm.awayPlayersJNums.push(team + num);

        // Crea un nuovo gruppo Forma + Testo
        let playerMaterial = vm.playerMaterial.clone();
        playerMaterial.color.set(color);
        vm.playerMesh = new THREE.Mesh(vm.playerGeometry, playerMaterial);
        vm.playerMesh.castShadow = false;
        vm.playerMesh.position.y = 5;
        // playerMesh.rotation.x = Math.PI / 2;

        // Numeri su piano
        var pGeometry = vm.playerNumPlaneGeom.clone();
        var texture = vm.createTextTexture(showNum ? num : "", 20, "white");
        var pMmaterial = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
        });
        vm.planeNum = new THREE.Mesh(pGeometry, pMmaterial);
        //planeNum.rotation.x -= Math.PI / 3; // Rotate the floor 90 degrees
        vm.planeNum.position.y = 22;
        let camPosition = vm.camera.position;
        //console.log(camPosition.y);

        var playerGroup = new THREE.Group();
        playerGroup.name = team + num;
        playerGroup.add(vm.playerMesh);
        playerGroup.add(vm.planeNum);
        vm.scene.add(playerGroup);

        let geomLightCone = vm.spotlightTemplateGeom.clone();
        let material = vm.spotlightTemplateMat.clone();

        let meshLight = new THREE.Mesh(geomLightCone, material);
        meshLight.name = team + num + "SL";
        meshLight.position.y = 40;
        meshLight.visible = false;
        vm.scene.add(meshLight);
      }

      var playerGroup = vm.scene.getObjectByName(team + num);
      playerGroup.visible = true;
      playerGroup.children[1].lookAt(vm.camera.position);

      //      playerGroup.position.x = x - vm.image_length / 2;
      //      playerGroup.position.y = 5;
      //      playerGroup.position.z = y - vm.image_height / 2;

      var spotlight = vm.scene.getObjectByName(team + num + "SL");

      // Verifico se il giocatore è in allarme
      const playersT1 = vm.$store.state.players.playersAlarmsType1;
      const playersT2 = vm.$store.state.players.playersAlarmsType2;
      const pKey = team + "|" + num;
      if (playersT2.includes(pKey)) {
        spotlight.material.color.setHex(0xff3333);
        spotlight.visible = true;
      } else if (playersT1.includes(pKey)) {
        spotlight.material.color.setHex(0xffaf33);
        spotlight.visible = true;
      } // remove previous tweens if needed //TWEEN.removeAll(); // build the tween to go ahead // Se la distanza da punto precedente è inferiore a 10 mt faccio l'animazione altrimenti sposto brutalmente
      /* 

      const ballDistance = Math.sqrt(
        Math.pow(x - ball.x, 2) + Math.pow(y - ball.y, 2)
      );
      if (ballDistance < 20) {
        spotlight.visible = true;
      }

 */

      const newX = x - vm.image_length / 2;
      const newY = y - vm.image_height / 2;
      const a = new THREE.Vector3(newX, 0, newY);
      const distanceV = playerGroup.position.distanceTo(a);

      if (distanceV <= 100 && (vm.poly == "None" || vm.poly == undefined)) {
        // 10 mt sono 100 px
        TweenMax.to([playerGroup.position, spotlight.position], 1, {
          x: x - vm.image_length / 2,
          z: y - vm.image_height / 2,
          ease: "none",
        });
      } else {
        playerGroup.position.x = newX;
        playerGroup.position.z = newY;
        spotlight.position.x = newX;
        spotlight.position.z = newY;
      }
      // start the first
      //tweenHead.start();
    },
    renderBall(vm, x, y, rot) {
      let ball = vm.scene.getObjectByName("ball");
      ball.visible = true;
      const newX = x - vm.image_length / 2;
      const newY = y - vm.image_height / 2;
      const a = new THREE.Vector3(newX, 0, newY);
      const distanceV = ball.position.distanceTo(a);
      ball.position.y = 10;
      if (distanceV <= 200 && (vm.poly == "None" || vm.poly == undefined)) {
        // 10 mt sono 100 px
        TweenMax.to(ball.position, 1, {
          x: x - vm.image_length / 2,
          z: y - vm.image_height / 2,
          ease: "none",
        });
      } else {
        ball.position.x = x - vm.image_length / 2;
        ball.position.z = y - vm.image_height / 2;
      }
    },

    renderPolygon(vm, polygon, color, index, zIdx, centerPoint) {
      // La tecnica è quella di tenere una lista di poligoni ma mettere visibili sono quelli che servono

      // provo ad ordinare per evitare gli scambi di vertice ma non funziona perfettamente
      //polygon = this.sortPolygonClockwise(polygon);

      // Creo il Mesh del poligono se non esiste
      if (index > vm.polygonsCount) {
        var shape = new THREE.Shape();
        //var geometry = new THREE.ShapeBufferGeometry(polygonShape);
        // Dummy shape
        shape.moveTo(0, 0);
        for (var j = 1; j < 22; j++) {
          shape.lineTo(j, j * 10);
        }
        shape.lineTo(0, 210);
        shape.lineTo(0, 0);
        // var geometry = new THREE.ExtrudeGeometry(shape, {
        //   amount: 100,
        //   bevelEnabled: false,
        //   extrudeMaterial: 1,
        //   material: 0
        // });
        var geometry = new THREE.ShapeGeometry(shape);

        // Cambio Material in base alla presenza del centerPoint ovvero se Voroi
        var material;
        if (centerPoint != undefined) {
          var texture = this.createRadialGradientTexture(
            centerPoint[0],
            centerPoint[1],
            5,
            50,
            color,
            "orange"
          );
          material = new THREE.MeshBasicMaterial({
            map: texture,
            opacity: 0.8,
            transparent: true,
            visible: true,
            side: THREE.DoubleSide,
            wireframe: false,
          });
        } else {
          material = new THREE.MeshBasicMaterial({
            color: color,
            opacity: 0.5,
            transparent: true,
            visible: true,
            side: THREE.DoubleSide,
            wireframe: false,
          });
        }
        var mesh = new THREE.Mesh(geometry, material);

        mesh.name = "Poly" + index;
        vm.scene.add(mesh);

        // Add borders
        var geo = new THREE.EdgesGeometry(geometry); // or WireframeGeometry( geometry )
        // attributes
        var positions = new Float32Array(30 * 3); // 3 vertices per point
        geo.setAttribute("position", new THREE.BufferAttribute(positions, 3));

        // drawcalls
        geo.setDrawRange(0, 2);

        var mat = new THREE.LineBasicMaterial({
          color: 0xfefefe,
          linewidth: 2,
        });
        var wireframe = new THREE.Line(geo, mat);
        wireframe.name = "PolyBorders" + index;
        vm.scene.add(wireframe);
        //        wireframe.rotation.x -= Math.PI/2;

        vm.polygonsCount++;
      }

      /*
       * Render Bordi: Metto i punti x,y,z in sequenza dentro a positions
       */

      var meshB = this.scene.getObjectByName("PolyBorders" + index);
      meshB.visible = true;

      var positions = meshB.geometry.attributes.position.array;
      /*
       * Aggiornamenti a scatti senza Twinmax
       */
      var idx = 0;
      for (let i = 0; i < polygon.length; i++) {
        positions[idx++] = polygon[i][0] - vm.image_length / 2;
        positions[idx++] = 1;
        positions[idx++] = polygon[i][1] - vm.image_height / 2;
      }

      for (let i = polygon.length; i < 100; i++) {
        positions[idx++] = polygon[0][0] - vm.image_length / 2;
        positions[idx++] = 1;
        positions[idx++] = polygon[0][1] - vm.image_height / 2;
      }

      /*
       * Aggiornamento  con Twinmax
       */
      // for (let i = 0; i <= polygon.length; i++) {
      //   const pos = {
      //     x: positions[i * 3],
      //     y: positions[i * 3 + 1],
      //     z: positions[i * 3 + 2],
      //   };

      //   let vertex = polygon[i];
      //   // L'ultimo punto lo metto uguale al primo
      //   if (vertex == undefined) vertex = polygon[0];

      //   TweenMax.to(pos, {
      //     x: vertex[0] - vm.image_length / 2,
      //     y: 1,
      //     z: vertex[1] - vm.image_height / 2,
      //     duration: 1,
      //     // Make sure to tell it to update
      //     onUpdate: function() {
      //       positions[i * 3] = pos.x;
      //       positions[i * 3 + 1] = pos.y;
      //       positions[i * 3 + 2] = pos.z;

      //       meshB.geometry.attributes.position.needsUpdate = true;
      //     }.bind(this),
      //   });
      // }

      // for (let i = polygon.length; i < 100; i++) {
      //   positions[i * 3] = polygon[0][0] - vm.image_length / 2;
      //   positions[i * 3 + 1] = 1;
      //   positions[i * 3 + 2] = polygon[0][1] - vm.image_height / 2;
      // }

      // drawcalls
      meshB.geometry.setDrawRange(0, polygon.length + 1);
      meshB.geometry.attributes.position.needsUpdate = true;

      /*
       * Render Shape: Se esiste un centerPoint uso gradiente altrimenti uniforme
       */
      var mesh = this.scene.getObjectByName("Poly" + index);
      mesh.visible = true;

      var verts = mesh.geometry.vertices;
      // set the vertex index
      var v = 0;

      // Iterate over the vertices
      // CON SCATTI
      for (var j = 0; j < verts.length; j++) {
        // If there are still legitimate verts
        if (j < polygon.length) {
          verts[v].x = polygon[j][0] - vm.image_length / 2;
          verts[v].z = polygon[j][1] - vm.image_height / 2;
          verts[v].y = 1 * zIdx;

          // If we've got extra verts, bunch them up in the same place
        } else {
          verts[v].x = verts[v - 1].x;
          verts[v].y = verts[v - 1].y;
          verts[v].z = verts[v - 1].z;
        }

        v++;
      }

      // SENZA SCATTI NON FUNZIONA
      // for (var j = 0; j < verts.length; j++) {
      //   let vertex = polygon[j];
      //   // Se ci sono extra vertici li metto uguali al primo
      //   if (vertex == undefined) vertex = polygon[0];
      //   const pos = {
      //     x: verts[v].x,
      //     y: verts[v].y,
      //     z: verts[v].z,
      //   };
      //   TweenMax.to(pos, {
      //     x: vertex[0] - vm.image_length / 2,
      //     y: 1 * zIdx,
      //     z: vertex[1] - vm.image_height / 2,
      //     duration: 1,
      //     // Make sure to tell it to update
      //     onUpdate: function() {
      //       verts[v].x = pos.x;
      //       verts[v].y = pos.y;
      //       verts[v].z = pos.z;

      //       mesh.geometry.verticesNeedUpdate = true;
      //     }.bind(this),
      //   });
      //   v++;

      // }

      mesh.geometry.verticesNeedUpdate = true;

      if (centerPoint == undefined) {
        mesh.material.color.set(color);
      } else {
        // Material Gradients For Voronoi
        var shadowTexture = this.createRadialGradientTexture(
          centerPoint[0],
          centerPoint[1],
          5,
          100,
          color,
          "orange"
        );
        mesh.material.map = shadowTexture;
      }
    },
    clear() {
      // Disabilita tutti i giocatori e la palla
      for (var i = 0; i < this.homePlayersJNums.length; i++) {
        this.scene.getObjectByName(this.homePlayersJNums[i]).visible = false;
        this.scene.getObjectByName(
          this.homePlayersJNums[i] + "SL"
        ).visible = false;
        // this.scene.getObjectByName(this.homePlayersJNums[i] + "SLH").visible = false;
      }
      for (var i = 0; i < this.awayPlayersJNums.length; i++) {
        this.scene.getObjectByName(this.awayPlayersJNums[i]).visible = false;
        this.scene.getObjectByName(
          this.awayPlayersJNums[i] + "SL"
        ).visible = false;
        // this.scene.getObjectByName(this.awayPlayersJNums[i] + "SLH").visible = false;
      }
      // La palla la lascio sempre visibile. sarà poi il render a gestire la posizione
      this.scene.getObjectByName("ball").visible = false;
    },
    clearWithFilter(newHomePlayers, newAwayPlayers) {
      let sceneChildren = this.scene.children;
      // Spengo tutti gli SL
      var listSL = sceneChildren
        .filter((child) => child.name.endsWith("SL"))
        .map((child) => child.name);
      listSL.forEach((element) => {
        this.scene.getObjectByName(element).visible = false;
      });

      // Creo la lista dei nomi degli oggetti della scene corrispondenti a tutti i giocatori
      const validPlayers = newHomePlayers
        .map((player) => "Home" + player.jerseyNum)
        .concat(newAwayPlayers.map((player) => "Away" + player.jerseyNum));

      // Disabilita solo i giocatori che non sono presenti nel nuovo record letto

      var listNotValidPlayers = sceneChildren
        .filter(
          (child) =>
            (child.name.startsWith("Home") || child.name.startsWith("Away")) &&
            !child.name.endsWith("SL") &&
            !validPlayers.includes(child.name)
        )
        .map((child) => child.name);
      listNotValidPlayers.forEach((element) => {
        this.scene.getObjectByName(element).visible = false;
      });

      this.scene.getObjectByName("ball").visible = false;
    },
    clearPolygons(visiblePolygons) {
      // Disabilita polygoni superflui
      for (var i = visiblePolygons; i <= this.polygonsCount; i++) {
        this.scene.getObjectByName("Poly" + i).visible = false;
        this.scene.getObjectByName("PolyBorders" + i).visible = false;
      }
    },
    createTextLabel() {
      var div = document.createElement("div");
      div.className = "text-label";
      div.style.position = "absolute";
      div.style.width = 100;
      div.style.height = 100;
      div.innerHTML = "hi there!";
      div.style.top = -1000;
      div.style.left = -1000;

      var _this = this;

      return {
        element: div,
        parent: false,
        position: new THREE.Vector3(0, 0, 0),
        setHTML: function(html) {
          this.element.innerHTML = html;
        },
        setParent: function(threejsobj) {
          this.parent = threejsobj;
        },
        updatePosition: function() {
          if (parent) {
            this.position.copy(this.parent.position);
          }

          var coords2d = this.get2DCoords(this.position, _this.camera);
          this.element.style.left = coords2d.x + "px";
          this.element.style.top = coords2d.y + "px";
        },
        get2DCoords: function(position, camera) {
          var vector = position.project(camera);
          vector.x = ((vector.x + 1) / 2) * window.innerWidth;
          vector.y = (-(vector.y - 1) / 2) * window.innerHeight;
          return vector;
        },
      };
    },
    makeTextSprite(vm, message, parameters) {
      if (parameters === undefined) parameters = {};

      var fontface = parameters.hasOwnProperty("fontface")
        ? parameters["fontface"]
        : "Arial";

      var fontsize = parameters.hasOwnProperty("fontsize")
        ? parameters["fontsize"]
        : 18;

      var borderThickness = parameters.hasOwnProperty("borderThickness")
        ? parameters["borderThickness"]
        : 4;

      var borderColor = parameters.hasOwnProperty("borderColor")
        ? parameters["borderColor"]
        : { r: 0, g: 0, b: 0, a: 1.0 };

      var backgroundColor = parameters.hasOwnProperty("backgroundColor")
        ? parameters["backgroundColor"]
        : { r: 255, g: 255, b: 255, a: 1.0 };

      //var spriteAlignment = parameters.hasOwnProperty("alignment") ?
      //	parameters["alignment"] : THREE.SpriteAlignment.topLeft;

      //var spriteAlignment = THREE.spriteAlignment.topLeft;

      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      context.font = "Bold " + fontsize + "px " + fontface;

      // get size data (height depends only on font size)
      var metrics = context.measureText(message);
      var textWidth = metrics.width;

      // background color
      //context.fillStyle   = "rgba(" + backgroundColor.r + "," + backgroundColor.g + ","
      //                + backgroundColor.b + "," + backgroundColor.a + ")";
      context.fillStyle = backgroundColor;
      // border color
      //context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + ","
      //                + borderColor.b + "," + borderColor.a + ")";
      context.fillStyle = backgroundColor;

      context.lineWidth = borderThickness;
      vm.roundRect(
        context,
        borderThickness / 2,
        borderThickness / 2,
        textWidth + borderThickness,
        fontsize * 1.4 + borderThickness,
        6
      );
      // 1.4 is extra height factor for text below baseline: g,j,p,q.

      // text color
      context.fillStyle = "rgba(0, 0, 0, 1.0)";

      context.fillText(message, borderThickness, fontsize + borderThickness);

      // canvas contents will be used for a texture
      var texture = new THREE.CanvasTexture(canvas);
      texture.needsUpdate = true;
      texture.minFilter = THREE.LinearFilter;
      texture.wrapS = THREE.ClampToEdgeWrapping;
      texture.wrapT = THREE.ClampToEdgeWrapping;

      var spriteMaterial = new THREE.SpriteMaterial({
        map: texture,
        //, useScreenCoordinates: false
        transparent: true,
      });
      var sprite = new THREE.Sprite(spriteMaterial);
      sprite.scale.set(100, 50, 1.0);
      return sprite;
    },
    // function for drawing rounded rectangles
    roundRect(ctx, x, y, w, h, r) {
      ctx.beginPath();
      ctx.moveTo(x + r, y);
      ctx.lineTo(x + w - r, y);
      ctx.quadraticCurveTo(x + w, y, x + w, y + r);
      ctx.lineTo(x + w, y + h - r);
      ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
      ctx.lineTo(x + r, y + h);
      ctx.quadraticCurveTo(x, y + h, x, y + h - r);
      ctx.lineTo(x, y + r);
      ctx.quadraticCurveTo(x, y, x + r, y);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    },
    createTextTexture(text, size, color) {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.width = 32;
      canvas.height = 32;

      context.font = "bold " + size + "pt Arial";

      var margin = 1;
      var textWidth = context.measureText(text).width;

      //context.strokeStyle = "black";
      //context.strokeRect(0, 0, canvas.width, canvas.height);

      //context.strokeStyle = "red";
      //context.strokeRect(canvas.width / 2 - textWidth / 2 - margin / 2, canvas.height / 2 - size / 2 - +margin / 2, textWidth + margin, size + margin);

      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = color;
      context.fillText(text, canvas.width / 2, canvas.height / 2);

      var texture = new THREE.Texture(canvas);
      texture.needsUpdate = true;

      return texture;
    },
    createRadialGradientTexture(
      x,
      y,
      innerRadius,
      outerRadius,
      colorStart,
      colorStop
    ) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      var gradient = ctx.createRadialGradient(
        x,
        y,
        innerRadius,
        x,
        y,
        outerRadius
      );
      gradient.addColorStop(0, colorStart);
      gradient.addColorStop(1, colorStop);

      ctx.arc(x, y, outerRadius * 2, 0, 2 * Math.PI);

      ctx.fillStyle = gradient;
      ctx.fill();

      var shadowTexture = new THREE.Texture(canvas);
      shadowTexture.needsUpdate = true;

      return shadowTexture;
    },
    // Ordina i vertici del poligono in senso orario
    sortPolygonClockwise(polygon) {
      // Find min max to get center
      // Sort from top to bottom
      polygon.sort((a, b) => a[1] - b[1]);

      // Get center y
      const cy = (polygon[0][1] + polygon[polygon.length - 1][1]) / 2;

      // Sort from right to left
      polygon.sort((a, b) => b[0] - a[0]);

      // Get center x
      const cx = (polygon[0][0] + polygon[polygon.length - 1][0]) / 2;

      // Center point
      //const center = { x: cx, y: cy };

      // Pre calculate the angles as it will be slow in the sort
      // As the points are sorted from right to left the first point
      // is the rightmost

      // Starting angle used to reference other angles
      var startAng;
      polygon.forEach((point) => {
        var ang = Math.atan2(point[1] - cy, point[0] - cx);
        if (!startAng) {
          startAng = ang;
        } else {
          if (ang < startAng) {
            // ensure that all points are clockwise of the start point
            ang += Math.PI * 2;
          }
        }
        point[3] = ang; // add the angle to the point
      });

      // Sort clockwise;
      polygon.sort((a, b) => a[3] - b[3]);

      return polygon;
    },
  },
};
</script>
<style>
#c3D {
  width: 100%;
  height: 100%;
}
#canvasWrapper3D {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 1;
}
.text-label {
  color: rgb(134, 25, 25);
  font-family: "Fira Mono", Monaco, "Andale Mono", "Lucida Console",
    "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  margin: -5px 0 0 15px;
  pointer-events: none;
}

#notes {
  color: #fff;
  font-family: "Fira Mono", Monaco, "Andale Mono", "Lucida Console",
    "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
