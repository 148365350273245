<template>
  <div class="Timeline:">
    <div class="Timeline:wrap">
      <Set
        @click.native="setOffline(true, idMatch, homeTeamName, awayTeamName, idGame)"
        :text="'Analyze '"
        :x="'23'"
        v-if="!postactive && !isOffline"
      />
      <Set
        @click.native="setOffline(false, idMatch, homeTeamName, awayTeamName, idGame)"
        :text="'Real Time'"
        :x="'12'"
        v-if="!postactive && isOffline"
      />
      <Doublerange v-if="postactive || isOffline" />
    </div>
  </div>
</template>

<script>
import Set from "@/components/general/header/elements/Set";
import Doublerange from "@/components/field/timeline/Doublerange.vue";
import idb from "@/mixins/idbPosts.js";
import axios from "axios";

export default {
  data() {
    return {
      timelineCheck: "",
    };
  },
  components: {
    Doublerange,
    Set,
  },
  computed: {
    gametime() {
      return this.$store.state.time.gametime;
    },
    postactive() {
      return this.$store.state.post.active;
    },
    isOffline() {
      return this.$store.state.offline;
    },
    idMatch() {
      return this.$store.state.idmatch;
    },
    homeTeamName() {
      return this.$store.state.field.hometeamname;
    },
    idGame() {
      return this.$store.state.idgame;
    },
    awayTeamName() {
      return this.$store.state.field.awayteamname;
    },
  },
  methods: {
    async setOffline(val, idMatch, hometeamname, awayteamname, idGame) {
      if (val) {
        // Salvo l'idgame corrente
        this.$store.commit("storeIdgame");
        // Interrompo la lettura del Websocket XY
        this.$store.commit("changeidgame", null);
        // Loads data into indexedDB
        await idb.clearData();
        const remainder = idGame % 1000;
        const livePort = 16000 + remainder;
        const baseParamStr = "00000000" + idMatch;
        const filename =
          baseParamStr.substring(baseParamStr.length - 8) + ".txt";
        const file_url =
          process.env.VUE_APP_LIVES_DOC_SERVER +
          ":" +
          livePort +
          "/livedocs/" +
          filename;
        const rows = await this.getRowsFromTxt(file_url);
        await idb.saveRows(rows);
        this.$store.commit("players", []);
        this.$store.commit("setpoststart", 0);
        this.$store.commit("setpostend", 0);
        const notEmptyRows = rows.filter((x) => x != "");
        const lastRow = notEmptyRows[notEmptyRows.length - 1];
        const rowParts = lastRow.split(";");
        const maxTime = rowParts[0];
        this.$store.commit("setgametime", maxTime);
        //this.$router.push("/field");
        // Attivo Modalità Offline
        this.$store.commit("setOffline");
      } else {
        this.$store.commit("players", []);
        this.$store.commit("setOnline");
        this.$store.commit("restoreIdgame");
      }
    },
    async getRowsFromTxt(file_url) {
      return new Promise((resolve, reject) => {
        axios.get(file_url).then((response) => {
          resolve(response.data.toString("utf8").split("\n"));
        });
      });
    },
  },
  mounted() {
    this.timelineCheck = "LineDouble";
  },
};
</script>

<style lang="scss" scoped>
.Timeline\: {
  z-index: 1000;
  position: absolute;
  bottom: 170px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  &wrap {
    display: flex;
    justify-content: center;
  }
}
</style>
