<template>
  <div class="Range:">
    <div class="Range:wrap">
      <!-- <div class="Range:title">Time range</div> -->
    </div>
    <div class="Range:tip"><div class="Range:tip_number"></div></div>
    <div class="Range:tip"><div class="Range:tip_number"></div></div>
    <div class="Range:cut">
      <div class="Range:cut_number"></div>
      <div class="Range:cut_number"></div>
    </div>
    <div class="Line:tip"><div class="Line:tip_number"></div></div>
    <div id="slider-range"></div>
    <div id="slider-line"></div>
    <div class="Range:set">
      <Set
        v-if="!this.isRunning"
        @click.native="skipBackward()"
        :text="'<<'"
        :x="'17'"
        :color="'#00fc77'"
        :classes="'Range:set_setdata'"
      />
      <Set
        v-if="!this.isRunning"
        @click.native="startTimer()"
        :text="'Play'"
        :x="'17'"
        :color="'#00fc77'"
        :classes="'Range:set_setdata'"
      />
      <Set
        v-if="this.isRunning"
        @click.native="stopTimer()"
        :text="'Pause'"
        :x="'17'"
        :color="'#00fc77'"
        :classes="'Range:set_setdata'"
      />
      <Set
        v-if="!this.isRunning"
        @click.native="skipForward()"
        :text="'>>'"
        :x="'17'"
        :color="'#00fc77'"
        :classes="'Range:set_setdata'"
      />
      <Set
        @click.native="setStartRange()"
        :text="'Set start'"
        :x="'17'"
        :color="'#00fc77'"
        :classes="'Range:set_setdata'"
      />
      <Set
        @click.native="setEndRange()"
        :text="'Set end'"
        :x="'18'"
        :color="'#00fc77'"
        :classes="'Range:set_setdata'"
      />
      <Set
        v-if="!offline"
        @click.native="sendPostDataRange()"
        :text="'KPI Calc'"
        :x="'13'"
        :color="'#ff0000'"
        :classes="'Range:set_setdata'"
      />
      <Set
        v-if="offline"
        @click.native="sendLiveDataRange()"
        :text="'KPI Calc'"
        :x="'13'"
        :color="'#ff0000'"
        :classes="'Range:set_setdata'"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "jquery-ui/ui/widgets/slider";
import "jquery-ui/themes/base/all.css";
import "jquery-ui/themes/base/slider.css";
import axios from "axios";
import Set from "@/components/general/header/elements/Set";
import convertTime from "@/mixins/converttime.js";
window.jQuery = $;
require("jquery-ui-touch-punch");
export default {
  data() {
    return {
      inittime: 0,
      stop: false,
      create: false,
      myTimer: null,
      timerId: null,
      isRunning: false,
    };
  },
  components: {
    Set,
  },
  methods: {
    sendDataLine() {
      /* Animazione Locale

      const data = {
        sessionid: this.sessionid,
        inittime: this.inittime,
      };

      axios
        .post(
          "http://" +
            process.env.VUE_APP_POSTS_SERVER +
            "/session/position",
          data
        )
        .then((e) => {
          console.log(e);
          this.stop = false;
        })
        .catch((err) => {
          console.log(err);
        });
        */
      this.stop = false;
      // this.$store.commit("incrAnimationCounter");
      // this.$store.commit("setinittime", this.inittime);

      this.inittime = Math.floor(this.inittime / 1000) * 1000;

      if (this.isRunning) {
        this.stopTimer();
        this.myTimer = parseInt(this.inittime);
        this.startTimer();
      } else {
        this.myTimer = parseInt(this.inittime);
      }
    },
    setStartRange() {
      $("#slider-range").slider("values", 0, this.timermill);
      const RangeTip_number = document.getElementsByClassName(
        "Range:tip_number"
      );
      RangeTip_number[0].innerHTML = convertTime(
        $("#slider-range").slider("values", 0)
      );
      const RangeCut_number = document.getElementsByClassName(
        "Range:cut_number"
      );
      RangeCut_number[0].innerHTML = convertTime(
        $("#slider-range").slider("values", 0)
      );
      this.$store.commit(
        "setpoststart",
        $("#slider-range").slider("values", 0)
      );
    },
    setEndRange() {
      $("#slider-range").slider("values", 1, this.timermill);
      const RangeTip_number = document.getElementsByClassName(
        "Range:tip_number"
      );
      RangeTip_number[1].innerHTML = convertTime(
        $("#slider-range").slider("values", 1)
      );
      const RangeCut_number = document.getElementsByClassName(
        "Range:cut_number"
      );
      RangeCut_number[1].innerHTML = convertTime(
        $("#slider-range").slider("values", 1)
      );
      this.$store.commit("setpostend", $("#slider-range").slider("values", 1));
    },
    sendPostDataRange() {
      // Informa il post manager di mandare i risultati dei calcoli via Websocket
      // TODO: Evitare il websocket e lavorare come per il live dato che in questo caso i dati sono indirizzati ad un solo client
      const data = {
        postid: this.postid,
        starttime: this.starttime,
        endtime: this.endtime,
      };

      axios
        .post(process.env.VUE_APP_POSTS_SERVER + "/post/interval", data)
        .then((response) => {
          this.$store.commit("activeredirect", true);
          this.$store.commit("players", response.data);
        })
        .catch((error) => {
          console.log("Error on post calc call " + error);
        });
    },
    sendLiveDataRange() {
      const data = {
        liveid: this.$store.state.storeIdgame,
        starttime: this.starttime,
        endtime: this.endtime,
        userid: this.userid,
      };
      const apiUrl = process.env.VUE_APP_POSTS_SERVER + "/livecalc/interval";
      axios
        .post(apiUrl, data)
        .then((response) => {
          //var calculationsString = JSON.parse(response.data);
          this.$store.commit("activeredirect", true);
          this.$store.commit("players", response.data);
        })
        .catch((error) => {
          console.log("Error on live calc call " + error);
        });
    },
    setPausedOn() {
      this.$store.commit("setpaused", true);
    },
    setPausedOff() {
      this.$store.commit("setpaused", false);
    },

    startTimer: function() {
      this.timerId = setInterval(() => this.countup(), 1000);
      this.isRunning = true;
    },
    stopTimer: function() {
      clearInterval(this.timerId);
      this.timerId = null;
      this.isRunning = false;
    },
    countup() {
      if (this.myTimer < this.gametime) {
        this.myTimer += 1000;
        this.$store.commit("setcommandtimer", this.myTimer);
      } else {
        clearInterval(this.timerId);
        this.myTimer = null;
        this.$store.commit("setcommandtimer", this.myTimer);
      }
    },
    skipForward: function() {
      if (this.myTimer < this.gametime) this.myTimer += 1000;
      this.$store.commit("setcommandtimer", this.myTimer);
    },
    skipBackward: function() {
      if (this.myTimer > 0) this.myTimer -= 1000;
      this.$store.commit("setcommandtimer", this.myTimer);
    },
  },
  computed: {
    sessionid() {
      return this.$store.state.post.sessionid;
    },
    postid() {
      return this.$store.state.post.postid;
    },
    gametime() {
      return this.$store.state.time.gametime;
    },
    timermill() {
      return this.$store.state.time.timermill;
    },
    starttime() {
      return this.$store.state.post.starttime;
    },
    endtime() {
      return this.$store.state.post.endtime;
    },
    activeredirect() {
      return this.$store.state.post.activeredirect;
    },
    players() {
      return this.$store.state.players.players;
    },
    offline() {
      return this.$store.state.offline;
    },
    minutes: function() {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds: function() {
      const seconds = this.totalTime - this.minutes * 60;
      return this.padTime(seconds);
    },
    userid() {
      return this.$store.state.user.user.id;
    },
  },
  watch: {
    timermill(val) {
      const thumb = document.getElementsByClassName("ui-slider-handle");
      const LineTip_number = document.getElementsByClassName("Line:tip_number");

      if (!this.stop && this.create) {
        // console.log(val);
        $("#slider-line").slider("value", val);

        // let p = val / this.gametime;
        // let corretNum = p * 100;
        // thumb[2].style.left = corretNum + "%";
        LineTip_number[0].innerHTML = convertTime(val);
      }
    },
    players(val) {
      //console.log(val);
      //console.log(this.activeredirect);
      if (this.activeredirect) {
        this.$router.push("/table");
        this.$store.commit("activeredirect", false);
      }
    },
  },
  mounted() {
    let vm = this;

    $(function() {
      const RangeTip_number = document.getElementsByClassName(
        "Range:tip_number"
      );
      const RangeCut_number = document.getElementsByClassName(
        "Range:cut_number"
      );
      const LineTip_number = document.getElementsByClassName("Line:tip_number");

      let endofgame = vm.gametime;

      if (vm.endtime != 0) {
        endofgame = vm.endtime;
      }

      $("#slider-range").slider({
        range: true,
        min: 0,
        max: vm.gametime,
        step: 1,
        values: [vm.starttime, endofgame],
        slide: function(event, ui) {
          RangeTip_number[0].innerHTML = convertTime(ui.values[0]);
          RangeTip_number[1].innerHTML = convertTime(ui.values[1]);
          RangeCut_number[0].innerHTML = convertTime(ui.values[0]);
          RangeCut_number[1].innerHTML = convertTime(ui.values[1]);
          vm.$store.commit("setpoststart", ui.values[0]);
          vm.$store.commit("setpostend", ui.values[1]);
        },
      });
      RangeTip_number[0].innerHTML = convertTime(
        $("#slider-range").slider("values", 0)
      );
      RangeTip_number[1].innerHTML = convertTime(
        $("#slider-range").slider("values", 1)
      );
      RangeCut_number[0].innerHTML = convertTime(
        $("#slider-range").slider("values", 0)
      );
      RangeCut_number[1].innerHTML = convertTime(
        $("#slider-range").slider("values", 1)
      );

      if (vm.starttime == 0) {
        vm.$store.commit("setpostend", 0);
      } else {
        vm.$store.commit("setpostend", vm.starttime);
      }

      if (vm.endtime == 0) {
        vm.$store.commit("setpostend", $("#slider-range").slider("values", 1));
      } else {
        vm.$store.commit("setpostend", vm.endtime);
      }

      $("#slider-line").slider({
        range: false,
        min: 0,
        max: vm.gametime,
        step: 100,
        value: 0,
        create: function(event, ui) {
          vm.create = true;
        },
        slide: function(event, ui) {
          LineTip_number[0].innerHTML = convertTime(ui.value);
          vm.inittime = Math.floor(parseInt(ui.value) / 1000) * 1000;
          if (!vm.isRunning) {
            vm.myTimer = vm.inittime;
            vm.$store.commit("setcommandtimer", vm.myTimer);
          }
        },
        start: function(event, ui) {
          vm.stop = true;
        },
        stop: function(event, ui) {
          vm.sendDataLine();
        },
      });
      LineTip_number[0].innerHTML = convertTime(
        $("#slider-line").slider("value")
      );

      const thumb = document.getElementsByClassName("ui-slider-handle");
      const tipRange = document.getElementsByClassName("Range:tip");
      const tipLine = document.getElementsByClassName("Line:tip");
      for (let i = 0; i < thumb.length; i++) {
        let top = thumb[i].offsetTop;
        let left = thumb[i].offsetLeft;
        if (i < 2) {
          tipRange[i].style.left = left + "px";
        } else {
          tipLine[0].style.left = left + "px";
        }
      }

      var observer = new MutationObserver(function(mutationsList, observer) {
        for (var mutation of mutationsList) {
          for (let i = 0; i < thumb.length; i++) {
            let left = thumb[i].offsetLeft;
            if (i < 2) {
              tipRange[i].style.left = left + "px";
            } else {
              tipLine[0].style.left = left + "px";
            }
          }
        }
      });

      observer.observe(document.getElementsByClassName("ui-slider-handle")[0], {
        attributes: true,
      });
      observer.observe(document.getElementsByClassName("ui-slider-handle")[1], {
        attributes: true,
      });
      observer.observe(document.getElementsByClassName("ui-slider-handle")[2], {
        attributes: true,
      });
    });
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
};
</script>

<style lang="scss">
.Range\: {
  @include inputrange();

  .ui-slider {
    &-handle:nth-child(1) {
      width: 2px;
      height: 1.6em;
      margin-left: 0;

      &:focus {
        outline: none;
      }
    }
  }
  .ui-state-default:nth-child(1) {
    background: darken($green, 50%);
    border: 2px solid darken($green, 10%);
  }
  .ui-state-active:nth-child(1) {
    // border: 1px solid $red;
    background: darken($red, 50%);
    border: 2px solid darken($green, 10%);
  }
  .ui-corner-all:nth-child(1) {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  &cut {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;

    &_number {
      position: absolute;
      top: -6px;
      &:first-child {
        left: -60px;
      }
      &:last-child {
        right: -60px;
      }
    }
  }
}
#slider-line {
  margin-top: -3px;
}
.Line\: {
  @include inputrange();
  &tip {
    width: 45px;
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 12px;
    position: absolute;
    top: 30px;
    margin-left: -20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: "";
      border: none;
    }

    &_number {
      height: 18px;
    }
  }
}
</style>
