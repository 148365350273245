var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "players" }, [
    _vm.team.length > 0
      ? _c(
          "div",
          { class: "playersTeam" + _vm.side },
          [
            _c("Controller", {
              attrs: { parameters: _vm.parameters, side: _vm.side }
            }),
            _vm._l(_vm.team, function(player, index) {
              return _c("Player", {
                key: "player-" + index,
                attrs: { player: player, side: _vm.side }
              })
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }