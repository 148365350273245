var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { attrs: { id: "Field" } },
      [
        _c("Header"),
        _c("Teams", {
          attrs: { home: _vm.homeTeamName, away: _vm.awayTeamName }
        }),
        _c(
          "div",
          { staticClass: "teams" },
          [
            _c("Players", { attrs: { team: _vm.teamparamHome, side: "home" } }),
            _c("Players", { attrs: { team: _vm.teamparamAway, side: "away" } })
          ],
          1
        ),
        !_vm.playvideo
          ? _c("SoccerField3D", {
              key: _vm.update,
              attrs: { matchSnapshot: _vm.animation }
            })
          : _vm._e(),
        _vm.playvideo ? _c("VideoPlayer") : _vm._e(),
        _vm.hasPostAnalysis ? _c("Timeline") : _vm._e(),
        _c("SetParameters"),
        _c("SetView"),
        _c("SetPoly"),
        _c("Card", { attrs: { player: _vm.player } }),
        _c("Menu"),
        _c("Setidgame"),
        _c("Setidpost")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }