<template>
  
<v-app>
  <div id="Field">
    <Header />
    <Teams :home="homeTeamName" :away="awayTeamName" />
    <div class="teams">
      <Players :team="teamparamHome" :side="'home'" />
      <Players :team="teamparamAway" :side="'away'" />
    </div>
    <SoccerField3D :matchSnapshot="animation" :key="update" v-if="!playvideo"/>
    <VideoPlayer v-if="playvideo"/>
    <Timeline v-if="hasPostAnalysis"/>
    <SetParameters />
    <SetView />
    <SetPoly />
    <Card :player="player" />
    <Menu />
    <Setidgame />
    <Setidpost />
  </div>
</v-app>
</template>

<script>
import axios from "axios";
import Teams from "@/components/field/Teams";
import Players from "@/components/field/players/Players";
import SetParameters from "@/components/field/setparameters/Setparameters";
import SetView from "@/components/field/setview/Setview";
import SetPoly from "@/components/field/setpoly/Setpoly";
import Header from "@/components/general/header/Header";
import Card from "@/components/general/card/Card.vue";
import SoccerField3D from "@/components/field/thefield/SoccerField3D.vue";
import Menu from "@/components/general/nav/menu.vue";
import Timeline from "@/components/field/timeline/Timeline.vue";
import Setidgame from "@/components/general/setidgame/idgame.vue";
import Setidpost from "@/components/general/setidpost/idpost.vue";
import VideoPlayer from "@/components/field/video/VideoPlayer.vue";
import getSocket from "@/mixins/socket.js";
import convertTime from "@/mixins/converttime.js";
import * as models from "@/mixins/models/models.js";
export default {
  data() {
    return {
      player: {},
      matchSnapshot: {},
      liveId: 0,
      update: 1,
      socket: null,
      postId: 0,
    };
  },
  components: {
    Teams,
    Players,
    Header,
    SetParameters,
    Card,
    SoccerField3D,
    SetView,
    Menu,
    Timeline,
    Setidgame,
    Setidpost,
    VideoPlayer,
    SetPoly,
  },
  watch: {
    card(val) {
      this.players.filter((e) => {
        if (
          e.jerseyNum == val.jerseyNum &&
          e.isOppositeTeam == val.isOppositeTeam
        ) {
          // console.log(e);
          return (this.player = e);
        }
      });
    },
    players(val) {
      //console.log(val);
    },
  },
  computed: {
    players() {
      return this.$store.state.players.players;
    },
    animation() {
      return this.$store.state.animation.animation;
    },
    idgame() {
      return this.$store.state.idgame;
    },
    idpost() {
      return this.$store.state.idpost;
    },
    postactive() {
      return this.$store.state.post.active;
    },
    isOffline() {
      return this.$store.state.offline;
    },
    filters() {
      return this.$store.state.field.filters;
    },
    card() {
      return this.$store.state.field.card.data;
    },
    hasPostAnalysis() {
      return this.$store.state.user.user.permissions.includes("ANALYZE");
    },
    teamHome() {
      return this.players.filter((e) => {
        if (e.isOppositeTeam == false) {
          return e;
        }
      });
    },
    teamparamHome() {
      return this.teamHome
        .filter((element) =>
          element.parameters.some((subElement) =>
            this.filters.includes(subElement.name)
              ? this.filters.includes(subElement.name)
              : subElement
          )
        )
        .map((element) => {
          let newElt = Object.assign({}, element); // copies element
          return {
            jerseyNum: newElt.jerseyNum,
            isOppositeTeam: newElt.isOppositeTeam,
            name: newElt.name,
            color: newElt.color,
            parameters: newElt.parameters.filter((subElement) =>
              this.filters.includes(subElement.name)
            ),
          };
        });
    },
    teamAway() {
      return this.players.filter((e) => {
        if (e.isOppositeTeam == true) {
          return e;
        }
      });
    },
    teamparamAway() {
      return this.teamAway
        .filter((element) =>
          element.parameters.some((subElement) =>
            this.filters.includes(subElement.name)
              ? this.filters.includes(subElement.name)
              : subElement
          )
        )
        .map((element) => {
          let newElt = Object.assign({}, element); // copies element
          return {
            jerseyNum: newElt.jerseyNum,
            isOppositeTeam: newElt.isOppositeTeam,
            name: newElt.name,
            color: newElt.color,
            parameters: newElt.parameters.filter((subElement) =>
              this.filters.includes(subElement.name)
            ),
          };
        });
    },
    homeTeamName() {
     return this.$store.state.field.hometeamname;
    },
    awayTeamName() {
      return this.$store.state.field.awayteamname;
    },
    playvideo() {
      return this.$store.state.playvideo;
    },
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    fetchData() {
      axios.get("/static/json/DataTemplate.json").then((response) => {
        this.players = response.data;
      });
    },
  },
  mounted() {},
  created() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    }
  },
};
</script>

<style lang="scss" scope>
#Field {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.teams {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 100;
  position: relative;
}
</style>
