<template>
  <div class="setparameters" ref="fullpanel">
    <div class="setparameters_box" ref="insidepanel">
      <img  v-if="appStyle == 'stats'"  
        @click="closePanel"
        class="setparameters_box:close"
        src="~@/assets/images/general/x_stats.svg"
        alt
      />
      <img  v-else  
        @click="closePanel"
        class="setparameters_box:close"
        src="~@/assets/images/general/x.svg"
        alt
      />
      <div class="setparameters_title">Setup parameters</div>
      <div class="setparameters_container" v-if="filters.length < 3">
        <div
          class="setparameters_container:single"
          v-for="(t, index) in parameters"
          :key="index"
        >
          <div
            v-if="filters.includes(t)"
            class="setparameters_container:single_val"
          >
            {{ t }}
          </div>
          <div
            v-else
            class="setparameters_container:single_valActive"
            @click="addParamenter(t)"
          >
            {{ t }}
          </div>
        </div>
      </div>
      <div class="setparameters_text" v-else>
        No more than 3 parameters can be selected
      </div>
    </div>
  </div>
</template>

<script>
import { TweenMax } from "gsap";
import axios from "axios";
export default {
  data() {
    return {
      newFilters: "",
      parameters: [],
    };
  },
  watch: {
    set(val) {
      if (val) {
        this.openPanel();
      }
    },
  },
  methods: {
    addParamenter(item) {
      this.newFilters = this.filters;
      this.newFilters.push(item);
      this.$store.commit("filtersParameter", this.newFilters);
    },
    openPanel() {
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 1,
        scale: 1,
        delay: 0.1,
      });
    },
    closePanel() {
      this.$store.commit("set", false);
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 0, delay: 0.1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 0,
        scale: 0.9,
      });
    },
    fetchData() {
      axios
//        .get(process.env.VUE_APP_API_URL + "/kpiuser/" + this.userid)
        .get(process.env.VUE_APP_API_URL + "/kpilive/" + (this.idgame != null ? this.idgame : this.storeIdgame), this.headerConfig)
        .then((response) => {
          if (response.data) {
            let data = response.data.filter(x => x.isAlarm == '0').map((e) => {
              return e.short_desc;
            });
            this.parameters = data;
          } 
        })
        .catch((err) => {
          axios
            .get(process.env.VUE_APP_API_URL + "/kpi", this.headerConfig)
            .then((response) => {
              if (response.data) {
                let data = response.data.filter(x => x.isAlarm == '0').map((e) => {
                  return e.short_desc;
                });
                this.parameters = data;
              }
            });
        });
    },
  },
  mounted() {},
  created() {
    this.fetchData();
  },
  computed: {
    set() {
      return this.$store.state.field.set;
    },
    filters() {
      return this.$store.state.field.filters;
    },
    userid() {
      return this.$store.state.user.user.id;
    },
    idgame() {
      return this.$store.state.idgame;
    },
    storeIdgame() {
      return this.$store.state.storeIdgame;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
};
</script>

<style lang="scss" scope>
.setparameters {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  //background-color: var(--bg-panel-color);
  visibility: hidden;
  text-align: center;
  z-index: 1000;
  &_box {
    color: var(--color);
    width: auto;
    display: inline-block;
    padding: 30px;
    background-image: var(--background-image);
    background-color: var(--bg-panel-color);
    border: var(--border);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    position: absolute;
    &\:close {
      padding: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 650px;
    &\:single {
      width: (100%/3);
      padding: 20px;
      &_val {
        color: var(--setparameters-color);
      }
      &_valActive {
      }
    }
  }
  &_text {
    width: 650px;
    text-align: center;
  }
}
</style>
