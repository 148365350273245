<template>
  <div class="players">
    <div :class="'playersTeam'+side" v-if="team.length > 0">
      <Controller :parameters="parameters" :side="side" />
      <Player v-for="(player, index) in team" :key="'player-'+index" :player="player" :side="side" />
    </div>
  </div>
</template>

<script>
import { TweenMax } from "gsap";
import Controller from "@/components/field/players/Controller";
import Player from "@/components/field/players/player/Player.vue";
export default {
  data() {
    return {};
  },
  components: {
    Controller,
    Player
  },
  props: {
    team: {
      type: Array,
      required: true
    },
    side: {
      type: String,
      required: true
    }
  },
  watch: {
    hidehome(val) {
      if (val) {
        this.hidePlayerName("home");
      } else {
        this.showPlayerName("home");
      }
    },
    hideaway(val) {
      if (val) {
        this.hidePlayerName("away");
      } else {
        this.showPlayerName("away");
      }
    }
  },
  computed: {
    hidehome() {
      return this.$store.state.field.hidehome;
    },
    hideaway() {
      return this.$store.state.field.hideaway;
    },
    parameters() {
      return this.team.map(e => {
        return e.parameters;
      });
    }
  },
  methods: {
    hidePlayerName(side) {
      console.log(side);
      const details = document.getElementsByClassName("player_details" + side);
      const name = document.getElementsByClassName(
        "player_details" + side + ":name" + side
      );
      TweenMax.staggerTo(name, 0.2, { autoAlpha: 0 }, 0.03);
      TweenMax.staggerTo(details, 0.3, { width: 35, delay: 0.2 }, 0.03);
    },
    showPlayerName(side) {
      const details = document.getElementsByClassName("player_details" + side);
      const name = document.getElementsByClassName(
        "player_details" + side + ":name" + side
      );
      TweenMax.staggerTo(details, 0.3, { width: 180 }, 0.03);
      TweenMax.staggerTo(name, 0.2, { autoAlpha: 1, delay: 0.2 }, 0.03);
    }
  },
  mouted() {}
};
</script>

<style lang="scss">
.players {
  display: inline-block;
  &Teamhome,
  &Teamaway,
  &Team {
    color: var(--color);
    &_header {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
  }
  &Teamaway {
    width: 344px;
    justify-self: flex-end;
  }
}
</style>