<template>
  <div class="setgame" ref="fullpanel">
    <div class="setgame_box" ref="insidepanel">
      <img
        @click="closePanel"
        class="setgame_box:close"
        src="~@/assets/images/general/x.svg"
        alt
      />
      <div class="setgame_title">Setup Game ID</div>
      <div class="setgame_container">
        <div
          class="setgame_container:single"
          v-for="(t, index) in listid"
          :key="index"
        >
          <div class="setgame_container:single_val" @click="setGame(t.liveId)">
            {{ t.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { TweenMax } from "gsap";
export default {
  data() {
    return {
      listid: "",
    };
  },
  watch: {
    game(val) {
      // console.log(val);
      if (val) {
        this.openPanel();
      }
    },
  },
  methods: {
    setGame(t) {
      this.$store.commit("changeidgame", t);
      this.closePanel();
      // location.reload();
    },
    getIds() {
      if (this.userid)
        axios
          .get(
            process.env.VUE_APP_API_URL + "/lives4user/" + this.userid,
            this.headerConfig
          )
          .then((response) => {
            this.listid = response.data;
          });
    },
    openPanel() {
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 1,
        scale: 1,
        delay: 0.1,
      });
    },
    closePanel() {
      this.$store.commit("setgame", false);
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 0, delay: 0.1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 0,
        scale: 0.9,
      });
    },
  },
  computed: {
    game() {
      return this.$store.state.game;
    },
  },
  created() {
//    this.getIds();
  },
  headerConfig() {
    return this.$store.getters.getHeaderConfig;
  },
  userid() {
    return this.$store.state.user.user.id;
  },
};
</script>

<style lang="scss" scoped>
.setgame {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-panel-color);
  visibility: hidden;
  text-align: center;
  z-index: 1000;
  &_box {
    color: white;
    width: auto;
    display: inline-block;
    padding: 30px;
    background-image: var(--background-image);
    border: var(--border);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    position: absolute;
    &\:close {
      padding: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 650px;
    &\:single {
      width: (100%/3);
      padding: 20px;
      &_val {
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 30px;
      }
      &_valActive {
      }
    }
  }
  &_text {
    width: 650px;
    text-align: center;
  }
}
</style>
