var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "fullpanel", staticClass: "setpost" }, [
    _c("div", { ref: "insidepanel", staticClass: "setpost_box" }, [
      _c("img", {
        staticClass: "setpost_box:close",
        attrs: { src: require("@/assets/images/general/x.svg"), alt: "" },
        on: { click: _vm.closePanel }
      }),
      _c("div", { staticClass: "setpost_title" }, [_vm._v("Setup Post ID")]),
      _c(
        "div",
        { staticClass: "setpost_container" },
        _vm._l(_vm.listid, function(t, index) {
          return _c(
            "div",
            { key: index, staticClass: "setpost_container:single" },
            [
              _c(
                "div",
                {
                  staticClass: "setpost_container:single_val",
                  on: {
                    click: function($event) {
                      return _vm.setPost(t.postId)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(t.name) + " ")]
              )
            ]
          )
        }),
        0
      )
    ]),
    _c("div", { staticClass: "container" }, [
      _vm.isInitial || _vm.isSaving
        ? _c(
            "form",
            { attrs: { enctype: "multipart/form-data", novalidate: "" } },
            [
              _c("h1", [_vm._v("Upload images")]),
              _c("div", { staticClass: "dropbox" }, [
                _c("input", {
                  staticClass: "input-file",
                  attrs: {
                    type: "file",
                    multiple: "",
                    name: _vm.uploadFieldName,
                    disabled: _vm.isSaving,
                    accept: "image/*"
                  },
                  on: {
                    change: function($event) {
                      _vm.filesChange($event.target.name, $event.target.files)
                      _vm.fileCount = $event.target.files.length
                    }
                  }
                }),
                _vm.isInitial
                  ? _c("p", [
                      _vm._v(" Drag your file(s) here to begin"),
                      _c("br"),
                      _vm._v(" or click to browse ")
                    ])
                  : _vm._e(),
                _vm.isSaving
                  ? _c("p", [
                      _vm._v("Uploading " + _vm._s(_vm.fileCount) + " files...")
                    ])
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _vm.isSuccess
        ? _c("div", [
            _c("h2", [
              _vm._v(
                "Uploaded " +
                  _vm._s(_vm.uploadedFiles.length) +
                  " file(s) successfully."
              )
            ]),
            _c("p", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v("Upload again")]
              )
            ]),
            _c(
              "ul",
              { staticClass: "list-unstyled" },
              _vm._l(_vm.uploadedFiles, function(item) {
                return _c("li", { key: item.url }, [
                  _c("img", {
                    staticClass: "img-responsive img-thumbnail",
                    attrs: { src: item.url, alt: item.originalName }
                  })
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.isFailed
        ? _c("div", [
            _c("h2", [_vm._v("Uploaded failed.")]),
            _c("p", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v("Try again")]
              )
            ]),
            _c("pre", [_vm._v(_vm._s(_vm.uploadError))])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }