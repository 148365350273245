<template>
  <div id="videoWrapper3D">
    <div id="video"></div>
  </div>
</template>

<script>
//import MainStreaming from "@/utils/mainstreaming.js";
export default {
  name: "VideoPlayer",
  data() {
    return {
      myplayer: null,
      currentTime: 0,
      myTimer: null,
      timerId: null,
      isRunning: false,
      pInterval: [],
      evCnt: 0,
      events: [
        {
          start: 1000,
          end: 1005,
        },
        {
          start: 1508,
          end: 1518,
        },
        {
          start: 3000,
          end: 3015,
        },
        {
          start: 4000,
          end: 4010,
        },
        {
          start: 5000,
          end: 5010,
        },
      ],
    };
  },

  mounted() {
    let vm = this;
    // this.$loadScript(
    //   "https://webtools-95f6192f836442089f16374bc48c0154.msvdn.net/js/mainstreaming.js"
    // )
    const videoUrl = this.$store.state.videoUrl;
    const videoId = this.$store.state.videoId;
    const videoSkin = this.$store.state.videoSkin;
    vm.pInterval = this.$store.state.periodsIntervals;
    this.$loadScript(videoUrl)
      .then(() => {
        var config = {
          containerID: "video",
          controls: true,
          skinId: videoSkin,
        };
        vm.myplayer = new MainStreaming.embed(videoId, config); // eslint-disable-line
        /*
        vm.myplayer.onProgress = myFunctionOnProgress; 
        vm.myplayer.onFirstPlay = function () {alert("First")}; 
        MainStreaming.instances['MsPlayer-video'].onFirstPlay = function () {alert("First")};// eslint-disable-line
        */
        document.getElementById("video").addEventListener("loaded", (e) => {
          console.log(e);
          vm.myplayer.listenTo("play", function(e) {
            console.log(e.detail.msg + " - " + e.detail.time);
          });
          vm.myplayer.listenTo("pause", function(e) {
            console.log(e.detail.msg + " - " + e.detail.time);
          });
          vm.myplayer.listenTo("seeked", function(e) {
            console.log(e.detail.msg + " - " + e.detail.target);
          });
          vm.myplayer.listenTo("seeking", function(e) {
            console.log(e.detail.msg + " - " + e.detail.target);
          });
          //vm.myplayer.instance.getCurrentTime();
        });
      })
      .catch(() => {
        // Failed to fetch script
      });

    // load Script
    // let mainstreamingScript = document.createElement("script");
    // mainstreamingScript.setAttribute(
    //   "src",
    //   "https://webtools-95f6192f836442089f16374bc48c0154.msvdn.net/js/mainstreaming.js"
    // );
    // document.head.appendChild(mainstreamingScript); // mainstreamingScript.onload = () => {
    // var config = {
    //   containerID: "video",
    //   html5Player: true,
    //   wmode: "direct",
    //   cuepoint: true,
    // };
    // console.log(this);
    // let myplayer = new MainStreaming.embed("BNdxxgGYtDWA", config); // eslint-disable-line
    // console.log(myplayer);
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    getInfo() {
      this.currentTime = this.myplayer.getCurrentTime();
      console.log(this.currentTime);
    },
    play() {
      this.myplayer.play();
    },
    pause() {
      this.myplayer.pause();
      clearInterval(this.timerId);
      this.timerId = null;
      this.isRunning = false;
    },
    startTimer: function(nextStop) {
      this.timerId = setInterval(() => this.countup(nextStop), 500);
      this.isRunning = true;
    },
    stopTimer: function() {
      clearInterval(this.timerId);
      this.timerId = null;
      this.isRunning = false;
    },
    countup(nextStop) {
      this.getInfo();
      if (this.currentTime >= nextStop) {
        this.myplayer.pause();
        clearInterval(this.timerId);
        this.myTimer = null;
        this.isRunning = false;
      }
    },
    async runEvents() {
      // loop sugli eventi
      for (const ev of this.events) {
        // avvio l'evento ed aspetto che termini
        this.myplayer.seekTo(ev.start);
        this.myplayer.play();
        this.startTimer(ev.end);
        while (true) {
          // eslint-disable-line no-constant-condition
          if (this.isRunning) await this.sleep(500);
          else break;
        }
      }
    },
  },
};
</script>

<style>
#video {
  width: 100%;
  height: 100%;
}
#videoWrapper3D {
  position: absolute;
  width: 80%;
  height: 80%;
  right: 0;
  margin-left: 10%;
  top: 60%;
  left: 0;
  transform: translate(0, -60%);
  z-index: 1;
}
.text-label {
  color: rgb(134, 25, 25);
  font-family: "Fira Mono", Monaco, "Andale Mono", "Lucida Console",
    "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  margin: -5px 0 0 15px;
  pointer-events: none;
}
</style>
