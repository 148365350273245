var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "fullpanel", staticClass: "setparameters" }, [
    _c("div", { ref: "insidepanel", staticClass: "setparameters_box" }, [
      _vm.appStyle == "stats"
        ? _c("img", {
            staticClass: "setparameters_box:close",
            attrs: {
              src: require("@/assets/images/general/x_stats.svg"),
              alt: ""
            },
            on: { click: _vm.closePanel }
          })
        : _c("img", {
            staticClass: "setparameters_box:close",
            attrs: { src: require("@/assets/images/general/x.svg"), alt: "" },
            on: { click: _vm.closePanel }
          }),
      _c("div", { staticClass: "setparameters_title" }, [
        _vm._v("Setup parameters")
      ]),
      _vm.filters.length < 3
        ? _c(
            "div",
            { staticClass: "setparameters_container" },
            _vm._l(_vm.parameters, function(t, index) {
              return _c(
                "div",
                { key: index, staticClass: "setparameters_container:single" },
                [
                  _vm.filters.includes(t)
                    ? _c(
                        "div",
                        { staticClass: "setparameters_container:single_val" },
                        [_vm._v(" " + _vm._s(t) + " ")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "setparameters_container:single_valActive",
                          on: {
                            click: function($event) {
                              return _vm.addParamenter(t)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(t) + " ")]
                      )
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "setparameters_text" }, [
            _vm._v(" No more than 3 parameters can be selected ")
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }