var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.side == "home"
    ? _c("div", { class: "playersTeam_header" + _vm.side }, [
        _c("div", { class: "playersTeam_header:container" + _vm.side }, [
          _c("div", { staticClass: "controller" }, [
            _vm.isActivePlayersControl
              ? _c(
                  "div",
                  {
                    ref: "controls" + _vm.side,
                    staticClass: "controller_hide"
                  },
                  [
                    _c(
                      "button",
                      {
                        ref: "hide",
                        staticClass: "controller_hide:hide",
                        on: {
                          click: function($event) {
                            return _vm.hidePalyerName(_vm.side)
                          }
                        }
                      },
                      [_vm._v(" Hide ")]
                    ),
                    _c(
                      "button",
                      {
                        ref: "show",
                        staticClass: "controller_hide:show",
                        on: {
                          click: function($event) {
                            return _vm.showPalyerName(_vm.side)
                          }
                        }
                      },
                      [_vm._v(" Show ")]
                    )
                  ]
                )
              : _vm._e(),
            !_vm.isActivePlayersControl
              ? _c("div", { staticClass: "controller_hide" })
              : _vm._e(),
            _vm.isActivePlayersControl
              ? _c(
                  "div",
                  {
                    ref: "controls_arrows" + _vm.side,
                    class: "controller_arrows" + _vm.side
                  },
                  [
                    _c(
                      "div",
                      {
                        class: "controller_arrows" + _vm.side + ":left",
                        on: { click: _vm.hideParameters }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/field/arrow-left.svg")
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: "controller_arrows" + _vm.side + ":right",
                        on: { click: _vm.showparameters }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/field/arrow-right.svg")
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e(),
            !_vm.isActivePlayersControl
              ? _c("div", { class: "controller_arrows" + _vm.side })
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "parameters:left" },
            _vm._l(_vm.parameters[0], function(t, index) {
              return _c(
                "div",
                { key: index, staticClass: "parameters_single" },
                [
                  _vm.filters.includes(t.name)
                    ? _c(
                        "div",
                        {
                          class: "parameters_single:val" + _vm.side,
                          on: {
                            click: function($event) {
                              return _vm.removeParamFilter(t.name)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(t.name) + " ")]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ])
      ])
    : _c("div", { class: "playersTeam_header" + _vm.side }, [
        _c("div", { class: "playersTeam_header:container" + _vm.side }, [
          _c(
            "div",
            { staticClass: "parameters:right" },
            _vm._l(_vm.parameters[0], function(t, index) {
              return _c(
                "div",
                { key: index, staticClass: "parameters_single" },
                [
                  _vm.filters.includes(t.name)
                    ? _c(
                        "div",
                        {
                          class: "parameters_single:val" + _vm.side,
                          on: {
                            click: function($event) {
                              return _vm.removeParamFilter(t.name)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(t.name) + " ")]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "controller:right" }, [
            _vm.isActivePlayersControl
              ? _c(
                  "div",
                  {
                    ref: "controls" + _vm.side,
                    staticClass: "controller_hide"
                  },
                  [
                    _c(
                      "button",
                      {
                        ref: "hide",
                        staticClass: "controller_hide:hide",
                        on: {
                          click: function($event) {
                            return _vm.hidePalyerName(_vm.side)
                          }
                        }
                      },
                      [_vm._v(" Hide ")]
                    ),
                    _c(
                      "button",
                      {
                        ref: "show",
                        staticClass: "controller_hide:show",
                        on: {
                          click: function($event) {
                            return _vm.showPalyerName(_vm.side)
                          }
                        }
                      },
                      [_vm._v(" Show ")]
                    )
                  ]
                )
              : _vm._e(),
            !_vm.isActivePlayersControl
              ? _c("div", { staticClass: "controller_hide" })
              : _vm._e(),
            _vm.isActivePlayersControl
              ? _c(
                  "div",
                  {
                    ref: "controls_arrows" + _vm.side,
                    class: "controller_arrows" + _vm.side
                  },
                  [
                    _c(
                      "div",
                      {
                        class: "controller_arrows" + _vm.side + ":left",
                        on: { click: _vm.showparameters }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/field/arrow-left.svg")
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: "controller_arrows" + _vm.side + ":right",
                        on: { click: _vm.hideParameters }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/field/arrow-right.svg")
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm._e(),
            !_vm.isActivePlayersControl
              ? _c("div", { class: "controller_arrows" + _vm.side })
              : _vm._e()
          ])
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }