var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Range:" }, [
    _c("div", { staticClass: "Range:wrap" }),
    _vm._m(0),
    _vm._m(1),
    _vm._m(2),
    _vm._m(3),
    _c("div", { attrs: { id: "slider-range" } }),
    _c("div", { attrs: { id: "slider-line" } }),
    _c(
      "div",
      { staticClass: "Range:set" },
      [
        !this.isRunning
          ? _c("Set", {
              attrs: {
                text: "<<",
                x: "17",
                color: "#00fc77",
                classes: "Range:set_setdata"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.skipBackward()
                }
              }
            })
          : _vm._e(),
        !this.isRunning
          ? _c("Set", {
              attrs: {
                text: "Play",
                x: "17",
                color: "#00fc77",
                classes: "Range:set_setdata"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.startTimer()
                }
              }
            })
          : _vm._e(),
        this.isRunning
          ? _c("Set", {
              attrs: {
                text: "Pause",
                x: "17",
                color: "#00fc77",
                classes: "Range:set_setdata"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.stopTimer()
                }
              }
            })
          : _vm._e(),
        !this.isRunning
          ? _c("Set", {
              attrs: {
                text: ">>",
                x: "17",
                color: "#00fc77",
                classes: "Range:set_setdata"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.skipForward()
                }
              }
            })
          : _vm._e(),
        _c("Set", {
          attrs: {
            text: "Set start",
            x: "17",
            color: "#00fc77",
            classes: "Range:set_setdata"
          },
          nativeOn: {
            click: function($event) {
              return _vm.setStartRange()
            }
          }
        }),
        _c("Set", {
          attrs: {
            text: "Set end",
            x: "18",
            color: "#00fc77",
            classes: "Range:set_setdata"
          },
          nativeOn: {
            click: function($event) {
              return _vm.setEndRange()
            }
          }
        }),
        !_vm.offline
          ? _c("Set", {
              attrs: {
                text: "KPI Calc",
                x: "13",
                color: "#ff0000",
                classes: "Range:set_setdata"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.sendPostDataRange()
                }
              }
            })
          : _vm._e(),
        _vm.offline
          ? _c("Set", {
              attrs: {
                text: "KPI Calc",
                x: "13",
                color: "#ff0000",
                classes: "Range:set_setdata"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.sendLiveDataRange()
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Range:tip" }, [
      _c("div", { staticClass: "Range:tip_number" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Range:tip" }, [
      _c("div", { staticClass: "Range:tip_number" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Range:cut" }, [
      _c("div", { staticClass: "Range:cut_number" }),
      _c("div", { staticClass: "Range:cut_number" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Line:tip" }, [
      _c("div", { staticClass: "Line:tip_number" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }