var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team" }, [
    _c("div", { staticClass: "team_home" }, [
      _vm.appStyle != "stats"
        ? _c("img", {
            staticClass: "team_home:flag",
            attrs: { src: require("@/assets/images/field/home-red.png") }
          })
        : _vm._e(),
      _c("div", { staticClass: "team_name" }, [_vm._v(_vm._s(_vm.home))]),
      _vm.appStyle == "stats"
        ? _c("img", {
            staticClass: "team_home:line",
            attrs: {
              src: require("@/assets/images/field/lineLeft_stats.svg"),
              alt: ""
            }
          })
        : _c("img", {
            staticClass: "team_home:line",
            attrs: {
              src: require("@/assets/images/field/lineLeft.svg"),
              alt: ""
            }
          })
    ]),
    _c("div", { staticClass: "team_away" }, [
      _vm.appStyle != "stats"
        ? _c("img", {
            staticClass: "team_away:flag",
            attrs: { src: require("@/assets/images/field/away.png") }
          })
        : _vm._e(),
      _c("div", { staticClass: "team_name" }, [_vm._v(_vm._s(_vm.away))]),
      _vm.appStyle == "stats"
        ? _c("img", {
            staticClass: "team_away:line",
            attrs: {
              src: require("@/assets/images/field/lineRight_stats.svg"),
              alt: ""
            }
          })
        : _c("img", {
            staticClass: "team_away:line",
            attrs: {
              src: require("@/assets/images/field/lineRight.svg"),
              alt: ""
            }
          })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }