<template>
  <div class="setpost" ref="fullpanel">
    <div class="setpost_box" ref="insidepanel">
      <img
        @click="closePanel"
        class="setpost_box:close"
        src="~@/assets/images/general/x.svg"
        alt
      />
      <div class="setpost_title">Setup Post ID</div>
      <div class="setpost_container">
        <div
          class="setpost_container:single"
          v-for="(t, index) in listid"
          :key="index"
        >
          <div class="setpost_container:single_val" @click="setPost(t.postId)">
            {{ t.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <!--UPLOAD-->
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="isInitial || isSaving"
      >
        <h1>Upload images</h1>
        <div class="dropbox">
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="
              filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length;
            "
            accept="image/*"
            class="input-file"
          />
          <p v-if="isInitial">
            Drag your file(s) here to begin<br />
            or click to browse
          </p>
          <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
        </div>
      </form>
      <!--SUCCESS-->
      <div v-if="isSuccess">
        <h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Upload again</a>
        </p>
        <ul class="list-unstyled">
          <li v-for="item in uploadedFiles" :key="item.url">
            <img
              :src="item.url"
              class="img-responsive img-thumbnail"
              :alt="item.originalName"
            />
          </li>
        </ul>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Uploaded failed.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { TweenMax } from "gsap";
import AdmZip from "adm-zip";

import { upload } from "./file-upload.service"; // real service
import { wait } from "./utils";

import idb from "@/mixins/idbPosts.js";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  data() {
    return {
      listid: "",
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
    };
  },
  watch: {
    post(val) {
      // console.log(val);
      if (val) {
        this.openPanel();
      }
    },
  },
  methods: {
    setPost(postid) {
      /**
       *  check if sessionid is set if not go ahead for the set up sessionid
       *  once it replies make a POST /session/create with sessionid
       *
       *  if not make just save the idpost in the store
       *
       *  Then make the post the create the animation POST /session/post
       *  post url-> http://ksportlive.racebi.com
       *  docs: http://ksportlive.racebi.com:3901/docs/api/post/session/post.md
       */
      if (!this.sessionid) {
        axios
          .get(
            process.env.VUE_APP_API_URL + "/session/getid/" + this.userid,
            this.headerConfig
          )
          .then((response) => {
            console.log(response);
            const sessionid = response.data.sessionid;
            this.sessionCreate(sessionid, postid);
            // make the post
            this.closePanel();
          });
      } else {
        this.sessionPost(this.sessionid, postid);
        this.closePanel();
      }
    },
    sessionCreate(sessionid, postid) {
      axios
        .post(process.env.VUE_APP_POSTS_SERVER + "/session/create", {
          sessionid: sessionid,
        })
        .then((response) => {
          console.log(response);
          this.sessionPost(sessionid, postid);
        });
    },
    async sessionPost(sessionid, postid) {
      const data = {
        sessionid: sessionid,
        postid: postid,
      };
      await axios
        .post(process.env.VUE_APP_POSTS_SERVER + "/session/post", data)
        .then((response) => {
          console.log(response);
          this.$store.commit("setsessionid", sessionid);
          this.$store.commit("setpostid", postid);
          this.$store.commit("setgametime", response.data.maxtime);
        });
      // Loads data into indexedDB
      await idb.clearData();
      const file_url =
        process.env.VUE_APP_POSTS_SERVER + "/postdocs/" + postid + ".zip";
      const rows = await this.getRowsFromZip(file_url);
      await idb.saveRows(rows);
    },
    getIds() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "/posts4user/" + this.userid,
          this.headerConfig
        )
        .then((response) => {
          this.listid = response.data;
        });
    },
    openPanel() {
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 1,
        scale: 1,
        delay: 0.1,
      });
    },
    closePanel() {
      this.$store.commit("setpost", false);
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 0, delay: 0.1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 0,
        scale: 0.9,
      });
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      upload(formData)
        .then(wait(1500)) // DEV ONLY: wait for 1.5s
        .then((x) => {
          this.uploadedFiles = [].concat(x);
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

      // save it
      this.save(formData);
    },
    async getRowsFromZip(file_url) {
      return new Promise((resolve, reject) => {
        axios({
          url: file_url,
          method: "GET",
          responseType: "arraybuffer",
          responseEncoding: null,
        }).then((response) => {
          // convert arraybuffer to buffer
          var buffer = new Buffer(response.data.byteLength);
          var view = new Uint8Array(response.data);
          for (var i = 0; i < buffer.length; ++i) {
            buffer[i] = view[i];
          }

          var zip = new AdmZip(buffer);
          var zipEntries = zip.getEntries();
          resolve(
            zipEntries[0]
              .getData()
              .toString("utf8")
              .split("\n")
          );
        });
      });
    },
  },
  computed: {
    post() {
      return this.$store.state.post.post;
    },
    postid() {
      return this.$store.state.post.postid;
    },
    sessionid() {
      return this.$store.state.post.sessionid;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  created() {
    this.getIds();
  },
  mounted() {
    this.reset();
  },
};
</script>

<style lang="scss" scoped>
.setpost {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-panel-color);
  visibility: hidden;
  text-align: center;
  z-index: 1000;
  &_box {
    color: white;
    width: auto;
    display: inline-block;
    padding: 30px;
    background-image: var(--background-image);
    border: var(--border);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    position: absolute;
    &\:close {
      padding: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 650px;
    &\:single {
      width: (100%/3);
      padding: 20px;
      &_val {
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 30px;
      }
      &_valActive {
      }
    }
  }
  &_text {
    width: 650px;
    text-align: center;
  }
}

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
