import { render, staticRenderFns } from "./Setparameters.vue?vue&type=template&id=f672c598&"
import script from "./Setparameters.vue?vue&type=script&lang=js&"
export * from "./Setparameters.vue?vue&type=script&lang=js&"
import style0 from "./Setparameters.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/bonomma/SW_DEV/ktrack/ksport-ktrackapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f672c598')) {
      api.createRecord('f672c598', component.options)
    } else {
      api.reload('f672c598', component.options)
    }
    module.hot.accept("./Setparameters.vue?vue&type=template&id=f672c598&", function () {
      api.rerender('f672c598', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/field/setparameters/Setparameters.vue"
export default component.exports