var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "fullpanel", staticClass: "setpolygon" }, [
    _c("div", { ref: "insidepanel", staticClass: "setpolygon_box" }, [
      _vm.appStyle == "stats"
        ? _c("img", {
            staticClass: "setpolygon_box:close",
            attrs: {
              src: require("@/assets/images/general/x_stats.svg"),
              alt: ""
            },
            on: { click: _vm.closePanel }
          })
        : _c("img", {
            staticClass: "setpolygon_box:close",
            attrs: { src: require("@/assets/images/general/x.svg"), alt: "" },
            on: { click: _vm.closePanel }
          }),
      _c("div", { staticClass: "setpolygon_title" }, [
        _vm._v("SELECT POLYGON VIEW")
      ]),
      _c(
        "div",
        { staticClass: "setpolygon_container" },
        _vm._l(_vm.polys, function(t, index) {
          return _c(
            "div",
            { key: index, staticClass: "setpolygon_container:single" },
            [
              _c(
                "div",
                {
                  staticClass: "setpolygon_container:single_val",
                  on: {
                    click: function($event) {
                      return _vm.setPoly(t)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(t.poly) + " ")]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }