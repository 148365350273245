<template>
  <div class="team">
    <div class="team_home">
      <img v-if="appStyle != 'stats'" class="team_home:flag" src="~@/assets/images/field/home-red.png" />
      <div class="team_name">{{home}}</div>
      <img v-if="appStyle == 'stats'"  class="team_home:line" src="~@/assets/images/field/lineLeft_stats.svg" alt />
      <img v-else  class="team_home:line" src="~@/assets/images/field/lineLeft.svg" alt />
    </div>
    <div class="team_away">
      <img v-if="appStyle != 'stats'" class="team_away:flag" src="~@/assets/images/field/away.png" />
      <div class="team_name">{{away}}</div>
      <img v-if="appStyle == 'stats'"  class="team_away:line" src="~@/assets/images/field/lineRight_stats.svg" alt />
      <img v-else  class="team_away:line" src="~@/assets/images/field/lineRight.svg" alt />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    home: {
      type: String,
      required: true
    },
    away: {
      type: String,
      required: true
    }
  },
  computed: {
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
};
</script>

<style lang="scss" scoped>
.team {
  color: var(--color);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 50px;
  &_name {
    font-weight: bold;
    font-size: 26px;
    margin-top: -30px;
    position: relative;
  }
  &_home,
  &_away {
    position: relative;
    &\:flag {
      margin-top: -80px;
    }
  }
  &_home {
    &\:flag {
      position: absolute;
      left: 100px;
    }
    &\:line {
      margin-left: -15px;
    }
  }
  &_away {
    &\:flag {
      position: absolute;
      right: 100px;
    }
    &\:line {
      margin-top: 8px;
      float: right;
      margin-right: -15px;
    }
  }
}
</style>