var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Timeline:" }, [
    _c(
      "div",
      { staticClass: "Timeline:wrap" },
      [
        !_vm.postactive && !_vm.isOffline
          ? _c("Set", {
              attrs: { text: "Analyze ", x: "23" },
              nativeOn: {
                click: function($event) {
                  return _vm.setOffline(
                    true,
                    _vm.idMatch,
                    _vm.homeTeamName,
                    _vm.awayTeamName,
                    _vm.idGame
                  )
                }
              }
            })
          : _vm._e(),
        !_vm.postactive && _vm.isOffline
          ? _c("Set", {
              attrs: { text: "Real Time", x: "12" },
              nativeOn: {
                click: function($event) {
                  return _vm.setOffline(
                    false,
                    _vm.idMatch,
                    _vm.homeTeamName,
                    _vm.awayTeamName,
                    _vm.idGame
                  )
                }
              }
            })
          : _vm._e(),
        _vm.postactive || _vm.isOffline ? _c("Doublerange") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }